<template>
  <GiftLayout
    is-christmas
    is-beg-header
  >
    <div class="beg-voicemod">
      <section v-if="!isEmailConfirmed">
        <h1
          class="beg-voicemod__title"
          role="title"
        >
          {{ $t('begVoicemod.header') }}
        </h1>

        <h3 class="beg-voicemod__subtitle">
          {{ $t('begVoicemod.subtitle') }}
        </h3>

        <p class="beg-voicemod__content">
          {{ $t('begVoicemod.descriptionOne') }}
        </p>

        <p
          class="beg-voicemod__content"
          v-html="textWithEmail"
        />

        <button
          class="beg-voicemod__button"
          @click="confirmEmail"
        >
          {{ $t('begVoicemod.confirmEmail') }}
        </button>
      </section>
      <section v-if="isEmailConfirmed">
        <h1
          class="beg-voicemod__title"
          role="title"
          v-html="$t('begVoicemod.magicLinkReady')"
        />
        <div class="beg-voicemod__copy">
          <input
            ref="giftLinkInput"
            v-model="linkToGift"
            class="beg-voicemod__input"
            type="text"
          >
          <button
            class="beg-voicemod__copy-button"
            @click="copyLink"
          >
            {{ copyText }}
          </button>
        </div>
      </section>
    </div>
  </GiftLayout>
</template>

<script>
import { mapState } from 'vuex';
import { ROUTER_PATHS } from '@/router/paths';
import { LOGIN_ACTIONS_CONSTANTS } from '@/domain/login';
import { CookieService } from '@/services/cookie';
import GiftLayout from '@/components/GiftLayout/GiftLayout.vue';
import { metricsService } from '@/metrics/metrics';
import { mParticleMetric } from '@/metrics';

export default {
    components: {
        GiftLayout,
    },
    data() {
        return {
            isEmailConfirmed: false,
            isCopyClicked: false,
            linkToGift: '',
        };
    },
    computed: {
        ...mapState('user_info_store', {
            userEmail: (state) => state.userInfo.email,
        }),
        textWithEmail() {
            return this.$t('begVoicemod.descriptionTwo').replace('(EMAIL)', `<b class="beg-voicemod__mail">${this.userEmail}</b>`);
        },
        copyText() {
            return this.isCopyClicked ? this.$t('begVoicemod.copiedLink') : this.$t('begVoicemod.copyLink');
        },
        hasValidSession() {
            return CookieService.getCookie('access_token');
        },
    },
    mounted() {
        if (!this.hasValidSession) {
            this.$router.push({
                path: ROUTER_PATHS.HOME,
                query: {
                    action: LOGIN_ACTIONS_CONSTANTS.BEG,
                },
            });
        }
        const eventData = {
            step: 'view',
            voicemod_system: 'account.voicemod.net',
        };
        mParticleMetric.track(mParticleMetric.EVENTS.BEG_VOICEMOD, eventData);
        metricsService.trackEvent(eventData);
    },
    methods: {
        copyLink() {
            this.isCopyClicked = true;
            setTimeout(() => {
                this.isCopyClicked = false;
            }, 2000);
            const giftLinkInputRef = this.$refs.giftLinkInput;
            giftLinkInputRef.select();
            document.execCommand('copy');
            window.getSelection().removeAllRanges(); // Remove selection
            const eventData = {
                step: 'link copied',
                voicemod_system: 'account.voicemod.net',
            };
            mParticleMetric.track(mParticleMetric.EVENTS.BEG_VOICEMOD, eventData);
            metricsService.trackEvent(eventData);
        },
        encodeStringToBase64(str) {
            const utf8 = encodeURIComponent(str);
            return btoa(utf8);
        },
        confirmEmail() {
            const eventData = {
                step: 'link generated',
                voicemod_system: 'account.voicemod.net',
            };
            mParticleMetric.track(mParticleMetric.EVENTS.BEG_VOICEMOD, eventData);
            metricsService.trackEvent(eventData);
            this.isEmailConfirmed = true;
            const mailToBase64 = this.encodeStringToBase64(this.userEmail);
            const route = this.$router.resolve({ path: ROUTER_PATHS.BEG_GIFTING, query: { ref: mailToBase64 }}).href;
            this.linkToGift = `${process.env.VUE_APP_HOST}/${route}`;
        },
    },
};
</script>

<style lang="scss" src="./BegView.scss" />
